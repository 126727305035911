import './AboutMe.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUniversity, faBuilding} from '@fortawesome/free-solid-svg-icons';
import bmstuLogo from './bmstuLogo.png';

function AboutMe() {
    return (
        <div className="about-me">
            <h2>About Me</h2>
            <div className="job-history">
                <div className="job">
                    <div className='job-info'>
                        <div className="company-logo">
                            <img
                                src="https://media.licdn.com/dms/image/C560BAQGJBK31jHxCPw/company-logo_100_100/0/1544625701678?e=1702512000&amp;v=beta&amp;t=VpPunvpB_0VaU3Zw8bNReqUUG1vE9cU3vDk8C0Rn2dA"
                                alt="Company Logo" className="company-logo-img"/>
                        </div>
                        <div className="company-details">
                            <h3><FontAwesomeIcon icon={faBuilding}/> Software Developer - Cloud Linux</h3>
                        </div>
                    </div>

                    <p>Dec 2022 – Present</p>
                    <ul>
                        <li>Java, Spring Boot, Neo4J, PostgreSQL</li>
                        <li>Maven, Gradle</li>
                    </ul>
                    <p>Responsibilities:</p>
                    <ul>
                        <li>Developed tools for identifying and resolving vulnerabilities in open source software.</li>
                        <li>Collaborated closely with build tools, including creating Maven plugins and integrating with
                            Gradle Tooling API.
                        </li>
                        <li>Developed integrations with CI/CD APIs (Jenkins, GitLab).</li>
                        <li>Utilized Selenium for web scraping activities and automating data extraction.</li>
                        <li>Implemented automated infrastructure setup using Ansible scripts.</li>
                    </ul>
                </div>

                <div className="job">

                    <div className='job-info'>
                        <div className="company-logo">
                            <img
                                src="https://media.licdn.com/dms/image/C4E0BAQFO68BVzYerJQ/company-logo_100_100/0/1656690187447?e=1702512000&v=beta&t=6HuZbN29qKWw6nCsHRRks5BuT0erJvwBtE4m_0Dxr9w"
                                alt="Company Logo" className="company-logo-img"/>
                        </div>
                        <div className="company-details">
                            <h3><FontAwesomeIcon icon={faBuilding}/> Software Developer - Revolut</h3>
                        </div>
                    </div>
                    <p>Oct 2021 – Sep 2022 (11 months)</p>
                    <ul>
                        <li>Java, Docker, GCP</li>
                        <li>New Relic, Metabase, PostgreSQL</li>
                    </ul>
                    <p>Responsibilities:</p>
                    <ul>
                        <li>Developed banking application for kids.</li>
                        <li>Participated in system design for features like kid-to-kid transfers and money requests from
                            parents.
                        </li>
                        <li>Troubleshooted clients' requests, investigated outages, and security incidents.</li>
                        <li>On-boarded a team of four developers.</li>
                    </ul>
                </div>
            </div>

            <div className="education">
                <h3>Education</h3>
                <div className="education-info">
                    <div className="education-logo">
                        <img src={bmstuLogo} alt="University Logo" className="university-logo"/>
                    </div>
                    <div className="education-details">
                        <p><FontAwesomeIcon icon={faUniversity}/> Bauman Moscow State Technical University, Moscow,
                            Russia</p>
                        <p>Master's in Radio-electronic systems and complexes</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutMe;
