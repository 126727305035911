import './HomePage.css';
import profilePic from './profilePic.jpg';

function HomePage() {
    return (
        <div className="homepage">
            <div className="intro">
                <h1>Welcome to My Personal Website</h1>
                <p>Hello, my name is Andrey Popov</p>
                <p>Java Software Developer</p>
                <img
                    src={profilePic} // Add the path to your profile image
                    alt="Andrei Popov"
                    className="profile-image"
                />
            </div>
            <div className="contact">
                <p>Contact: andreipopov702@gmail.com</p>
                <div className="social-icons">
                    {/* Add links to your social media profiles as icons */}
                    <a href="https://www.linkedin.com/in/andrewpopov30" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-linkedin"></i>
                    </a>
                    <a href="https://github.com/ffisherr" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-github"></i>
                    </a>
                    {/* Add more social media icons as needed */}
                </div>
            </div>
        </div>
    );
}

export default HomePage;
