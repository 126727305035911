import NavigationMenu from "../navigation/NavigationMenu";
import './Layout.css';

function Layout({ children }) {
    return (
        <div className="app">
            <NavigationMenu />
            <main className="content">{children}</main>
        </div>
    );
}

export default Layout;