import './NavigationMenu.css'; // Import your CSS file for styling
import { Link } from 'react-router-dom'; // Import Link from React Router for navigation

function NavigationMenu() {
    return (
        <nav className="navigation-menu">
            <ul className="menu-items">
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/about">About me</Link>
                </li>
            </ul>
        </nav>
    );
}

export default NavigationMenu;
