import HomePage from "./pages/home/HomePage";
import Layout from "./pages/layout/Layout";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import AboutMePage from "./pages/aboutme/AboutMePage"; // Import React Router for routing

function App() {
  return (
      <Router>
          <Layout>
              <Routes>
                  <Route exact path="/" element={<HomePage/>} />
                  <Route path="/about" element={<AboutMePage/>} />
              </Routes>
          </Layout>
      </Router>
  );
}

export default App;
